import React from "react"
import styled from "styled-components"

const Card = styled.div`
  display: flex;
  width: 86vw;
  max-width: 400px;
  padding: 12px;
  box-sizing: border-box;
  flex-direction: column;
  @media only screen and (min-height: 720px) and (min-width: 1280px) {
    display: flex;
    margin-left: 36px;
    margin-right: 36px;
  }
`

const Icon = styled.div`
  margin-left: 15px;
  width: 70px;
  @media only screen and (min-height: 720px) and (min-width: 1280px) {
    width: 80px;
  }
`

const Title = styled.div`
  font-size: calc(27px + (32 - 27) * ((100vw - 300px) / (1600 - 300)));
  margin-top: 10px;
  margin-bottom: 1em;
  color: black;
  font-weight: 700;
`

const Content = styled.article`
  color: black;
  font-weight: 300;
  font-size: calc(16px + (19 - 16) * ((100vw - 300px) / (1600 - 300)));
`

const ServiceCard = ({ icon, title, description }) => {
  return (
    <Card>
      <Icon>
        <img src={icon} />
      </Icon>
      <Title>{title}</Title>
      <Content>{description}</Content>
    </Card>
  )
}

export default ServiceCard
