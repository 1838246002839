import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import ServiceCard from "./ServiceCard"

const Deck = styled.div`
  position: absolute;
  display: flex;
  transition: transform 500ms cubic-bezier(0.24, 0.15, 0.53, 0.99) 0ms;
  transform: ${props => props.transform};
`

const ServiceCardDeck = ({ handlers, displacement, isMobile }) => {
  const data = useStaticQuery(graphql`
    query ServicePageQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/ServicePage/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              abstract
            }
            rawMarkdownBody
          }
        }
      }
      allFile(
        filter: {
          sourceInstanceName: { eq: "ServicePage" }
          internal: { mediaType: { eq: "image/svg+xml" } }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            publicURL
          }
        }
        distinct(field: name)
      }
    }
  `)

  const content = data.allMarkdownRemark.edges
  const icons = data.allFile.edges
  const distinct = data.allFile.distinct

  return (
    <Deck
      {...handlers}
      transform={isMobile ? `translateX(${displacement}px)` : ""}
    >
      {content.map(({ node }) => (
        <ServiceCard
          key={node.frontmatter.title}
          title={node.frontmatter.title}
          description={node.rawMarkdownBody}
          icon={
            icons[distinct.indexOf(node.frontmatter.abstract)].node.publicURL
          }
        />
      ))}
    </Deck>
  )
}

export default ServiceCardDeck
