import React from "react"

import "./about_page.css"
import PortfolioImage from "../../components/AboutPage/portfolio_image"

import about from "../../Markup/About"

const AboutPage = () => {
  return (
    <div id="about-page" className="about-page">
      <section>
        <div className="content">
          <div className="photo-block">
            <PortfolioImage style={{ marginRight: "1em", marginLeft: "1em" }} />
          </div>
          <hr />
          <div className="content-block">
            <hgroup>
              <h1 className="about-heading">About Me</h1>
              <h2 className="sub-heading">Web Developer - Teacher</h2>
            </hgroup>
            <article className="description">
              {about.map((paragraph, i) => (
                <p key={i}>{paragraph}</p>
              ))}
            </article>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutPage
