import React, { useState, useEffect } from "react"
import "./pricing_page.css"
import pricingBlobTop from "../../images/PricingBlobTop.svg"
import { useSwipeable } from "react-swipeable"

import useWindowDimensions from "../../APIs/window_dimensions"
import StepsPage from "../StepsPage/steps_page"
import stepsBlobBot from "../../images/StepsBlobBot.svg"
import AboutPage from "../AboutPage/about_page"
import pricing from "../../Markup/Pricing"
import PricingCard from "../../components/PricingCard/PricingCard"

import backArrow from "../../images/BackArrow.svg"
import forwardArrow from "../../images/ForwardArrow.svg"
import CarouselSteps from "../../components/CarouselSteps/CarouselSteps"
import { useTouchEvents } from "../../APIs/hasTouch"

const PricingBlobTop = () => (
  <img className="blob-top" src={pricingBlobTop} alt="" />
)
const StepsBlobBot = () => (
  <img className="blob-bot" src={stepsBlobBot} alt="" />
)

const PricingPage = () => {
  const { height, width } = useWindowDimensions()
  const [currentGrid, setCurrentGrid] = useState(0)
  const [isMobile, setIsMobile] = useState(true)
  const { hasTouch } = useTouchEvents()

  useEffect(() => {
    if (height >= 720 && width >= 1280) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [height, width])

  const cardWidth = Math.min(400, 0.86 * width)

  const cardDisplacement = cardWidth * -1 * currentGrid

  const marginLeft = Math.max(16, 0.05 * width)

  const calcTranslateX = cardDisplacement - 12 * currentGrid + marginLeft + 6

  const handleSwipe = dir => {
    let newOrder = currentGrid

    switch (dir) {
      case "NEXT": {
        if (currentGrid !== 2) newOrder = currentGrid + 1
        setCurrentGrid(newOrder)
        break
      }
      case "PREV": {
        if (currentGrid !== 0) newOrder = currentGrid - 1
        setCurrentGrid(newOrder)
        break
      }
      default:
        return null
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe("NEXT")
    },
    onSwipedRight: () => {
      handleSwipe("PREV")
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false,
  })

  return (
    <div className="pricing-page">
      <PricingBlobTop />

      <div className="content-box" id="packages-page">
        <p
          className="heading"
          style={isMobile ? { marginLeft: marginLeft + 6 } : {}}
        >
          PACKAGES
        </p>
        <div className="deck-wrapper">
          {isMobile ? (
            <React.Fragment>
              <CarouselSteps currentStep={currentGrid} numSteps={3} />
              {currentGrid !== 0 ? (
                <div
                  className="prev deck-step"
                  onClick={() => handleSwipe("PREV")}
                  style={{ display: hasTouch ? "none" : "" }}
                >
                  <img className="arrows" src={backArrow} alt="back-arrow" />
                </div>
              ) : null}
              {currentGrid !== 2 ? (
                <div
                  className="next deck-step"
                  onClick={() => handleSwipe("NEXT")}
                  style={{ display: hasTouch ? "none" : "" }}
                >
                  <img
                    className="arrows"
                    src={forwardArrow}
                    alt="forward-arrow"
                  />
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
          <div
            className="card-deck"
            {...handlers}
            style={
              isMobile ? { transform: `translateX(${calcTranslateX}px)` } : {}
            }
          >
            {pricing.map(each => (
              <PricingCard pricing={each} key={each.title} />
            ))}
          </div>
        </div>
      </div>
      <StepsPage />
      <div className="bottom-block">
        <div className="blob-wrapper">
          <StepsBlobBot />
        </div>

        <AboutPage />
      </div>
    </div>
  )
}

export default PricingPage
