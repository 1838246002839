import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import LandingPage from "./LandingPage/landing_page"
import ServicePage from "./ServicePage/service_page"
import PhilosophyPage from "./PhilosophyPage/philosophy_page"
import PricingPage from "./PricingPage/pricing_page"
import ContactPage from "./ContactPage/contact_page"
import SEO from "../components/seo"
import IntroSection from "./IntroSection/IntroSection"

const IndexPage = () => (
  <React.Fragment>
    <Helmet>
      <title>MCubed Web Design and Development</title>
    </Helmet>
    <Layout>
      <SEO
        title="MCubed Web Design and Development"
        description="Professional Web Design and Development. "
      />
      <LandingPage />
      {/*<IntroSection />*/}
      <ServicePage />
      <PhilosophyPage />
      <PricingPage />
      <ContactPage />
    </Layout>
  </React.Fragment>
)

export default IndexPage
