import React, { useState, useEffect } from "react"
import "./service_page.css"
import useWindowDimensions from "../../APIs/window_dimensions"

import { useSwipeable } from "react-swipeable"

import serviceBlob from "../../images/ServiceBlob.svg"
import serviceBlob2 from "../../images/ServiceBlob2.svg"
import serviceBlob3 from "../../images/ServiceBlob3.svg"

import backArrow from "../../images/BackArrow.svg"
import forwardArrow from "../../images/ForwardArrow.svg"

import CarouselSteps from "../../components/CarouselSteps/CarouselSteps"
import { useTouchEvents } from "../../APIs/hasTouch"
import ServiceCardDeck from "../../components/ServicePage/ServiceCardDeck"

const BackgroundBlob = () => {
  return (
    <React.Fragment>
      <img src={serviceBlob} className="blob" alt="" />
      <img src={serviceBlob2} className="blob2" alt="" />
      <img src={serviceBlob3} className="blob3" alt="" />
    </React.Fragment>
  )
}

const ServicePage = () => {
  const { height, width } = useWindowDimensions()
  const [currentGrid, setCurrentGrid] = useState(0)
  const [isMobile, setIsMobile] = useState(true)
  const { hasTouch } = useTouchEvents()

  useEffect(() => {
    if (height >= 720 && width >= 1280) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [height, width])

  const handleSwipe = dir => {
    let newOrder = currentGrid

    switch (dir) {
      case "NEXT": {
        if (currentGrid !== 2) newOrder = currentGrid + 1
        setCurrentGrid(newOrder)
        break
      }
      case "PREV": {
        if (currentGrid !== 0) newOrder = currentGrid - 1
        setCurrentGrid(newOrder)
        break
      }
      default:
        return null
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe("NEXT")
    },
    onSwipedRight: () => {
      handleSwipe("PREV")
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false,
  })

  const cardWidth = Math.min(400, 0.86 * width)

  const cardDisplacement = cardWidth * -1 * currentGrid

  const marginLeft = Math.max(16, 0.05 * width)

  const calcTranslateX = cardDisplacement + marginLeft

  return (
    <div id="service-page" className="service-page">
      <div className="extension-block" />
      <BackgroundBlob />
      <p
        className="background-heading"
        style={{
          fontSize: isMobile
            ? ""
            : 50 + (175 - 50) * ((Math.min(1920, width) - 300) / (1600 - 300)),
        }}
      >
        SERVICES
      </p>

      <section>
        <div className="content-block">
          <p
            className="heading"
            style={
              isMobile ? { marginLeft: marginLeft + 12 } : { marginLeft: 12 }
            }
          >
            MY SERVICES
          </p>
          <div className="deck-wrapper">
            {isMobile ? (
              <React.Fragment>
                <CarouselSteps currentStep={currentGrid} numSteps={3} />
                {currentGrid !== 0 ? (
                  <div
                    className="prev deck-step"
                    onClick={() => handleSwipe("PREV")}
                    style={{ display: hasTouch ? "none" : "" }}
                  >
                    <img className="arrows" src={backArrow} alt="back-arrow" />
                  </div>
                ) : null}
                {currentGrid !== 2 ? (
                  <div
                    className="next deck-step"
                    onClick={() => handleSwipe("NEXT")}
                    style={{ display: hasTouch ? "none" : "" }}
                  >
                    <img
                      className="arrows"
                      src={forwardArrow}
                      alt="forward-arrow"
                    />
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}

            <ServiceCardDeck
              handlers={handlers}
              displacement={calcTranslateX}
              isMobile={isMobile}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default ServicePage
