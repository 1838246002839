const about = [
  `Hello! My name is Michael Ma. I am an Ontario teacher and web developer. I create websites with a modern, intuitive design.`,
  `I built my experience in web development while struggling to find an effective method for recording students' performance in my classes.
    My solution was to build an online database and analytics deck on my own. From there, my love for app development grew as I continued to build many other
    apps in the education space.`,
  `Now, I combine my knowledge and experience from web development with my love of modern design. I leverage my teaching experience to provide my clients with
    a smooth cooperation experience. As with students, I take the time to understand your values and present you with a project that is tailored for you. Akin to teaching
    any good lesson, I will provide a clear outline of the project, and will be open to feedback from you every step of the way.`,
]

export default about
