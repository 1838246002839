import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

const PortfolioImage = props => (
  <StaticQuery
    query={graphql`
      query {
        portfolio: file(relativePath: { eq: "PortfolioPictureColor.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.portfolio.childImageSharp.fluid
      return (
        <Img
          className="portfolio-image"
          fluid={imageData}
          style={props.style}
        />
      )
    }}
  />
)

export default PortfolioImage
